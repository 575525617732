function onSubmit() {
}

document.addEventListener('DOMContentLoaded', function() {
    document.querySelector(".g-recaptcha").addEventListener("click", function(e) {
        e.preventDefault()
        grecaptcha.ready(function() {
            grecaptcha.execute('6LdGe8MpAAAAAH_1vWHScXMD3F9w92JGNTB8CWhc', {action: 'submit'}).then(function(token) {
                document.getElementById("contact-form").submit();
            })
        })
    })
    // set up url checks
    const urlParams = new URLSearchParams(window.location.search)
    
    if (urlParams.get('error') === 'true') {
        document.getElementById('error').style.display = 'block'
    }

    if (urlParams.get('thankyou') === 'true') {
        document.getElementById('form').style.display = 'none'
        document.getElementById('thank-you').style.display = 'block'
    }

    // setup hero words
    const words = [
      "Don't talk about ideas, build them.",
      "Big ideas don’t need big builds right away",
      "Got an app idea?",
      "Did your MVP pass the vibe check?",
      "MVP: Small investment, big insight",
      "Currently accepting clients",
      "PROVE your concept"
    ]
    const wordSpan = document.getElementById('cta-text')
    let i = 0

    setInterval(() => {
        wordSpan.style.opacity = 0
        setTimeout(() => {
            wordSpan.textContent = words[i]
            wordSpan.style.opacity = 1
            i = (i + 1) % words.length
        }, 500)
    }, 10 * 1000)
})
